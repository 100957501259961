<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex succesuss">
    <div slot="no-body">
      <div class="justify-center items-center">
        <div class="d-theme-dark-bg" style="border-radius: 15px">
          <div class="p-5" style="max-width: 80vw">
            <div class="logo">
              <img
                width="200"
                height="50"
                class="TextLogo"
                alt=""
                src="@/assets/images/logo.png"
              />
            </div>
            <div class="mt-4 text-center">
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  aria-level="1"
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: bold;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 51, 0);
                    text-align: left;
                    text-indent: 49px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      font-weight: normal;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 1"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TabRun IPSelectionBlob BlobObject DragDrop BCX0 SCXW222454910"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      display: inline-block;
                      position: relative;
                      white-space: nowrap;
                      font-size: 11pt;
                      font-family: Calibri, sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      text-indent: 0px;
                      text-align: left;
                      width: 0px;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      class="TabChar BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        white-space: pre !important;
                        display: inline-block;
                      "
                      >&nbsp; &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      font-weight: normal;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 1"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TabRun IPSelectionBlob BlobObject DragDrop BCX0 SCXW222454910"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      display: inline-block;
                      position: relative;
                      white-space: nowrap;
                      font-size: 11pt;
                      font-family: Calibri, sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      text-indent: 0px;
                      text-align: left;
                      width: 0px;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      class="TabChar BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        white-space: pre !important;
                        display: inline-block;
                      "
                      >&nbsp; &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      font-weight: normal;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 1"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TabRun IPSelectionBlob BlobObject DragDrop BCX0 SCXW222454910"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      display: inline-block;
                      position: relative;
                      white-space: nowrap;
                      font-size: 11pt;
                      font-family: Calibri, sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      text-indent: 0px;
                      text-align: left;
                      width: 0px;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      class="TabChar BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        white-space: pre !important;
                        display: inline-block;
                      "
                      >&nbsp; &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      font-weight: normal;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 1"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TabRun IPSelectionBlob BlobObject DragDrop BCX0 SCXW222454910"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      display: inline-block;
                      position: relative;
                      white-space: nowrap;
                      font-size: 11pt;
                      font-family: Calibri, sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      text-indent: 0px;
                      text-align: left;
                      width: 0px;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      class="TabChar BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        white-space: pre !important;
                        display: inline-block;
                      "
                      >&nbsp; &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      font-weight: bold;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 1"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TabRun IPSelectionBlob BlobObject DragDrop BCX0 SCXW222454910"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      display: inline-block;
                      position: relative;
                      white-space: nowrap;
                      font-size: 11pt;
                      font-family: Calibri, sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      text-indent: 0px;
                      text-align: left;
                      width: 0px;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      class="TabChar BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        white-space: pre !important;
                        display: inline-block;
                      "
                      >&nbsp; &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 51, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      font-weight: bold;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 1"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335559685":24,"335559739":0,"335559740":259,"469777462":[735,1455,2175,2895,3615,4897],"469777927":[0,0,0,0,0,0],"469777928":[3,3,3,3,3,3]}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 51, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: center;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun Underlined BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: underline;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      font-weight: bold;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >TERMS &amp; CONDITIONS</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      font-weight: bold;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559685":5,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(255, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":14,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(255, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(255, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":14,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(255, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  aria-level="2"
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >1.</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 19.425px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun Underlined BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: underline;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >SCOPE</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335559685":9,"335559739":0,"335559740":259,"335559991":10}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 1px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":24,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >1.1</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Patient is seeking the Medical Procedure generally described as
                      (&ldquo;Medical Service&rdquo; or &ldquo;Medical Procedure&rdquo;).
                      &nbsp;Patient wishes to receive this Medical Procedure in a country
                      not of their current residence (&ldquo;Internationally&rdquo;).
                      &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 49px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":735,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >1.2</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as&nbsp;</span
                    ><span
                      class="NormalTextRun SpellingErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlSpellingErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >Doclinia.com</span
                    ><span
                      class="NormalTextRun SpellingErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlSpellingErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >&rsquo;s</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >screens every medical facility</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >, doctor, and travel provider prior to offering it as an option to
                      its&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Patients&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >to help&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >ensure</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;the Patient receives quality service.&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;presents options that are available to the Patient for the
                      desired Medical Services and facilitates and coordinates various
                      aspects of the process including but not limited to: medical
                      accommodations, treatment facility, recruitment of certain doctors,
                      and facilitates post-operative care.&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;negotiates with medical service and related providers to
                      optimize pricing and care for the Medical Procedure within its
                      network of medical service providers.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":725,"335559737":1,"335559739":34,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >1.3</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >For the purposes of this Agreement and&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >with regard to</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;the Patient, the parties acknowledge that&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;acts only to coordinate the Medical Procedure, the Patient
                      seeks.&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;is not acting as a medical advisor, physician, or providing
                      medical advice of any kind. Patient acknowledges Patient has or will
                      consult with trusted, qualified medical professionals prior to
                      traveling to receive, or engaging in, Medical Services. Patient
                      acknowledges Patient has chosen the Medical Service and related
                      accommodations independently and without influence from&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;Portal</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":725,"335559737":1,"335559739":60,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >1.4</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Based on Patient&rsquo;s choices,&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;Portal</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;works to create a Package that is comprised of service items,
                      including, but not limited to medical services. Any services chosen
                      by&nbsp;</span
                    ><span
                      class="NormalTextRun ContextualSpellingAndGrammarErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlContextualSpellingAndGrammarErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >Patient</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;are performed or provided by third parties independent
                      of&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >.&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;only negotiates for the Patient so the Patient receives the
                      benefit of&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as&nbsp;</span
                    ><span
                      class="NormalTextRun SpellingErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlSpellingErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >Doclinia.com</span
                    ><span
                      class="NormalTextRun SpellingErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlSpellingErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >&rsquo;s</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;volume and wholesale pricing for their Package. &nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":725,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >1.5</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Patient chooses the service items to be included in their Package
                      of services on&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey trading as&nbsp;</span
                    ><span
                      class="NormalTextRun SpellingErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlSpellingErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >Doclinia.com</span
                    ><span
                      class="NormalTextRun SpellingErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlSpellingErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >&apos;s</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;website&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >www.doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >. Each of the Patient chosen service items are booked and arranged
                      by&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;to encompass all aspects of the travel, accommodations, and
                      medical service. The Package does not, however, include incidental
                      charges, meals, or similar expenses unless specifically stated
                      herein. &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":725,"335559737":1,"335559739":34,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >1.6</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;Portal</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;agrees to provide reasonable assistance to&nbsp;</span
                    ><span
                      class="NormalTextRun ContextualSpellingAndGrammarErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlContextualSpellingAndGrammarErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >Patient</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;throughout the travel period of the Medical Tourism Package
                      to ensure a pleasant experience. Any disputes with third-party
                      service providers are the full responsibility of the Patient,
                      regardless of any assistance provided by&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >. &nbsp;Patient acknowledges third-party providers may include, but
                      are not limited to the medical service providers, providers of
                      lodging, or transportation providers. &nbsp;Patient hereby waives
                      and releases&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;from all liability arising from complications or conflicts
                      arising from third</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >party providers.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":14,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >1.7</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Patient understands and acknowledges complications can arise from
                      the Medical Procedure Patient has chosen, which can result in injury
                      and/or death. &nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Patient</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;understand&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;has no control or influence over complications arising from
                      the Medical Procedure. Accordingly, Patient hereby waives and
                      releases&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;from all liability arising from or damages caused by the
                      chosen Medical Procedure.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":725,"335559737":1,"335559739":34,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  aria-level="2"
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >2.</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 19.425px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun Underlined BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: underline;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >ESTIMATED COST</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335559685":9,"335559739":0,"335559740":259,"335559991":10}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 1px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":24,"335559737":1,"335559739":9,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >2.1 The total estimated price for the options outlined in
                      Patient&rsquo;s&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Package is&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >indicative&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >(&ldquo;Estimate</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&rdquo;). &nbsp;The Estimate is subject to change based on any
                      additions or changes requested and agreed to by&nbsp;</span
                    ><span
                      class="NormalTextRun ContextualSpellingAndGrammarErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlContextualSpellingAndGrammarErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >Patient</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;in writing. &nbsp;The Estimate can change based on factors
                      not within&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as&nbsp;</span
                    ><span
                      class="NormalTextRun SpellingErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlSpellingErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >Doclinia.com</span
                    ><span
                      class="NormalTextRun SpellingErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlSpellingErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >&rsquo;s</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;control, including, but not limited to additional services
                      engaged by&nbsp;</span
                    ><span
                      class="NormalTextRun ContextualSpellingAndGrammarErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlContextualSpellingAndGrammarErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >Patient</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >,&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >patient&rsquo;s case study</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >, or changes to services. &nbsp;The Estimate can also change if
                      complications arise, unknown conditions are discovered, or changes
                      to the Medical Services. &nbsp;Changes to the Medical Tourism
                      Package schedule or services, including cancellation of the Package,
                      may also result in additional fees or costs. &nbsp;Any such
                      additional cost or charges will be added to the Estimate.
                      &nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 24px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":374,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >2.2 Rescheduling of any or&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >all of</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;the Package is very difficult and will incur additional
                      costs. If Patient requests to reschedule the Package, Patient must
                      submit to&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;Portal</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;such request in writing&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >at least twenty-five (25) calendar days prior to the original
                      travel start date with an explanation why the rescheduling is
                      required.</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":27,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 49px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Failure to submit the rescheduling request within such time will
                      result in forfeiture of Patient&rsquo;s deposit.&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;may agree to allow the change or refuse the change in its
                      sole discretion without dispute. Patient may be required to pay
                      rescheduling costs and fees and may be required to submit an
                      additional deposit before&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;will agree to reschedule the Package. &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":735,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 24px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":374,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >2.3&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;will assist with payment arrangements which may vary
                      depending&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >on&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >requirements of&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >the</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;medical service providers, other third-party service
                      providers, or international travel requirements. The estimated price
                      for the Package is due and payable before the start of travel for
                      Patient&rsquo;s Medical Services. Failure to timely pay may result
                      in cancellation of a portion or the entire Package and may result in
                      cancellation fees. The Patient agrees to pay&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >all the amounts requested by the medical service provider
                      before</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;arrival to the Medical Service destination to cover any
                      incidental expenses.&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Any arising expenses during the surgery or treatment will be
                      covered by the patient upon arrival through a credit or debit card
                      guarantee</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 24px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":374,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  aria-level="2"
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >3.</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 19.425px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun Underlined BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: underline;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >REQUIRED PATIENT INFORMATION</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335559685":9,"335559739":0,"335559740":259,"335559991":10}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 1px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":24,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >3.1</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Patient will be required to complete paperwork and otherwise
                      provide information to&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >, Medical Service providers, and other third-party service
                      providers prior to start of travel for the Medical Procedure.
                      Patient agrees to truthfully and accurately provide any such
                      information, including execution of&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >any and all</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;medical releases, completion of all medical forms, and
                      provide all health information required to engage in the Medical
                      Services. Patient understands complete, accurate, and truthful
                      disclosure of medical history, health information, or other
                      information requested is necessary for engaging in travel or any
                      Medical Services.&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;shall protect Patient&rsquo;s&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >information&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >ac</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >cording to EU GDPR law</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;protections or, in absence thereof, protect such information
                      with no less than a reasonable degree of care. &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 49px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":735,"335559737":1,"335559739":8,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >3.2</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Each international destination&rsquo;s laws and regulations will
                      vary, including, but not limited to, laws governing visas,
                      passports, immunizations, and travel restrictions.&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >will</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;provide information regarding such international
                      requirements, but such information may not be complete. Patient
                      understands and agrees it is Patient&rsquo;s sole responsibility to
                      secure the necessary passports, visas, governmental authorizations,
                      immunizations, or any other requirements imposed by the legal
                      jurisdiction Patient will travel through or destination.
                      &nbsp;Patient&apos;s failure to secure the proper travel documents
                      may result in the cancellation of their package and forfeiture of
                      their deposit.&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;Portal</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;shall have no liability for Patient&rsquo;s failure to secure
                      all such requirements.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":725,"335559737":1,"335559739":34,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  aria-level="2"
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >4.</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 19.425px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun Underlined BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: underline;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >WAIVER, INDEMNIFICATION AND ASSUMPTION OF RISK</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335559685":9,"335559739":0,"335559740":259,"335559991":10}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 24px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":374,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >4.1</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Patien</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >t understands and accepts that all Medical Procedures may involve
                      serious risks including injury and/or death and such risks are
                      inherent and cannot be eliminated or completely avoided through
                      exercise of any degree of diligence or care. Participant further
                      understands and accepts that traveling internationally also involves
                      serious risks which are inherent and cannot be eliminated through
                      the exercise of any degree of diligence or care. &nbsp;PATIENT
                      ACKNOWLEDGES THESE RISKS AND HEREBY AGREES TO ASSUME ANY AND ALL
                      RISK ARISING FROM SUCH TRAVEL OR MEDICAL PROCEDURES. &nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 24px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":374,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >4.2</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >To the maximum extent allowed by law, Patient waives, releases, and
                      holds harmless&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >, as well as its&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >shareholders,&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >employees, representatives, heirs, assigns, and/or agents
                      (&ldquo;Released Parties&rdquo;) from any and all suits, claims,
                      proceedings, demands and/or incidents for medical complications,
                      bodily injury, death, and/or property damage of any person or
                      entity,</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >including derivative claims of any kind for pain, suffering, loss
                      of capacity, loss of earning, and/or loss of consortium
                      (&ldquo;Claims&rdquo;), which in any way arise out of or in
                      connection with&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Portal&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >trading as&nbsp;</span
                    ><span
                      class="NormalTextRun SpellingErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlSpellingErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >Doclinia.com</span
                    ><span
                      class="NormalTextRun SpellingErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlSpellingErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >&rsquo;s</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;Services.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":725,"335559737":1,"335559739":34,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >4.3</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Further, to the maximum extent allowed by law, Patient shall defend
                      and indemnify Released Parties from&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >any and all</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;such Claims.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":725,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >4.4</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.6px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >The Parties mutually waive, release, and relinquish any claim or
                      demand for consequential, special, punitive, incidental, and/or
                      indirect damages of any kind, regardless of the factual basis or
                      legal theory from which it arises.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":14,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  aria-level="2"
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >5.</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 19.425px;
                      font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun Underlined BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: underline;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >MISCELLANEOUS</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: none;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      data-ccp-parastyle="heading 2"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335559685":9,"335559739":0,"335559740":259,"335559991":10}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 1px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":24,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >5.1 Patient expressly agrees that this Agreement shall bind Patient
                      as well as each of its successors and assigns, including, but not
                      limited to Patient&apos;s family, assigns, estate, heirs, and
                      personal representatives.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 24px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":374,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >5.2 The Parties agree this Contract is severable and any provisions
                      contained herein which are found to be null, void, and/or
                      unenforceable shall be reformed to the maximum extent allowed under
                      applicable law to further the intent of the offending provision or
                      removed with all else in this Contract remaining
                      effective.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 24px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":374,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 16.8583px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >5.3 Except for the warranties expressly set forth in this
                      Contract,&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;Portal</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;trading as Doclinia.com</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;does not make any other express or implied warranties or
                      representations of any kind. &nbsp;All such other warranties and
                      representations are hereby disclaimed.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":734,"335559737":1,"335559739":1,"335559740":238,"335559991":360}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 16.8583px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 24px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":374,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >5.4 This Contract shall be governed and construed in accordance
                      with the laws of&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >the&nbsp;</span
                    ></span
                  ><span
                    class="TextRun Underlined BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      text-decoration: underline;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >UAE</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;withou</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >t regard to conflict of law principles. &nbsp;If any Claim arises
                      out of this Contract or&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >Cure Journey</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;Portal</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;trading as&nbsp;</span
                    ><span
                      class="NormalTextRun SpellingErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlSpellingErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >Doclinia.com</span
                    ><span
                      class="NormalTextRun SpellingErrorV2 BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        background-repeat: repeat-x;
                        background-position: left bottom;
                        background-image: var(
                          --urlSpellingErrorV2,
                          url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                        );
                        border-bottom: 1px solid transparent;
                      "
                      >&rsquo;s</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;Services, the Parties shall, as a condition precedent to
                      arbitration, engage in mediation of any&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >dispute, unless the Parties agree otherwise in writing. &nbsp;Any
                      dispute which is not settled or disposed of by mediation shall be
                      referred to binding arbitration. &nbsp;The arbitration proceedings
                      shall take&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >place in</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;Dubai</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >5.5 No waiver of any provision of&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >document</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;shall be deemed, or shall constitute, a waiver of any other
                      provision,&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >whether or not</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;similar, nor shall any waiver constitute a continuing waiver.
                      &nbsp;No waiver shall be binding unless executed in writing by the
                      Party making the waiver.&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 24px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":374,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 48px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: -24px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >5.6 This&nbsp;</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >document</span
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;constitutes the entire agreement between the Parties
                      pertaining to its subject matter and it supersedes all prior
                      contemporaneous agreements, representations and understandings of
                      the Parties. &nbsp;No supplement, modification or amendment of this
                      Contract shall be binding unless executed in writing by
                      all&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":729,"335559737":1,"335559739":1,"335559740":248,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":14,"335559737":1,"335559739":1,"335559740":259,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 18.3458px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TabRun IPSelectionBlob BlobObject DragDrop BCX0 SCXW222454910"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      display: inline-block;
                      position: relative;
                      white-space: nowrap;
                      font-size: 11pt;
                      font-family: Calibri, sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      text-indent: 0px;
                      text-align: left;
                      width: 0px;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      class="TabChar BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        white-space: pre !important;
                        display: inline-block;
                      "
                      >&nbsp; &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TabRun IPSelectionBlob BlobObject DragDrop BCX0 SCXW222454910"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      display: inline-block;
                      position: relative;
                      white-space: nowrap;
                      font-size: 11pt;
                      font-family: Calibri, sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      text-indent: 0px;
                      text-align: left;
                      width: 0px;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      class="TabChar BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        white-space: pre !important;
                        display: inline-block;
                      "
                      >&nbsp; &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TabRun IPSelectionBlob BlobObject DragDrop BCX0 SCXW222454910"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      display: inline-block;
                      position: relative;
                      white-space: nowrap;
                      font-size: 11pt;
                      font-family: Calibri, sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      text-indent: 0px;
                      text-align: left;
                      width: 0px;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      class="TabChar BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        white-space: pre !important;
                        display: inline-block;
                      "
                      >&nbsp; &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TabRun IPSelectionBlob BlobObject DragDrop BCX0 SCXW222454910"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      display: inline-block;
                      position: relative;
                      white-space: nowrap;
                      font-size: 11pt;
                      font-family: Calibri, sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      text-indent: 0px;
                      text-align: left;
                      width: 0px;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      class="TabChar BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        white-space: pre !important;
                        display: inline-block;
                      "
                      >&nbsp; &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TabRun IPSelectionBlob BlobObject DragDrop BCX0 SCXW222454910"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      display: inline-block;
                      position: relative;
                      white-space: nowrap;
                      font-size: 11pt;
                      font-family: Calibri, sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      text-indent: 0px;
                      text-align: left;
                      width: 0px;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      class="TabChar BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        white-space: pre !important;
                        display: inline-block;
                      "
                      >&nbsp; &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TabRun IPSelectionBlob BlobObject DragDrop BCX0 SCXW222454910"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      display: inline-block;
                      position: relative;
                      white-space: nowrap;
                      font-size: 11pt;
                      font-family: Calibri, sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      text-indent: 0px;
                      text-align: left;
                      width: 0px;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      class="TabChar BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        white-space: pre !important;
                        display: inline-block;
                      "
                      >&nbsp; &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.5667px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TabRun IPSelectionBlob BlobObject DragDrop BCX0 SCXW222454910"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      display: inline-block;
                      position: relative;
                      white-space: nowrap;
                      font-size: 11pt;
                      font-family: Calibri, sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      text-indent: 0px;
                      text-align: left;
                      width: 0px;
                      color: rgb(0, 0, 0);
                    "
                    ><span
                      class="TabChar BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        white-space: pre !important;
                        display: inline-block;
                      "
                      >&nbsp; &nbsp;&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":384,"335559737":1,"335559739":1,"335559740":248,"335559991":370,"469777462":[735,1455,2175,2895,3615,4335,6856,5776,6496,7216,7936,8656],"469777927":[0,0,0,0,0,0,0,0,0,0,0,0],"469777928":[3,3,3,3,3,3,3,3,3,3,3,3]}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 12pt;
                      line-height: 19.6333px;
                      font-family: Calibri, Calibri_EmbeddedFont, Calibri_MSFontService,
                        sans-serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 0px 0px 1px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: justify;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":24,"335559737":3,"335559739":1,"335559740":240,"335559991":10}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW222454910"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <p
                  class="Paragraph BCX0 SCXW222454910"
                  style="
                    margin: 0px 677px 0px 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow-wrap: break-word;
                    font-weight: normal;
                    font-style: normal;
                    vertical-align: baseline;
                    font-kerning: none;
                    background-color: transparent;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    text-indent: 0px;
                  "
                >
                  <span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 12pt;
                      font-style: italic;
                      line-height: 19.95px;
                      font-family: Calibri, Calibri_EmbeddedFont, Calibri_MSFontService,
                        sans-serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="TextRun BCX0 SCXW222454910"
                    data-contrast="none"
                    lang="EN-US"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-variant-ligatures: none !important;
                      color: rgb(0, 0, 0);
                      font-size: 11pt;
                      line-height: 17.85px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                    "
                    ><span
                      class="NormalTextRun BCX0 SCXW222454910"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                      "
                      >&nbsp;</span
                    ></span
                  ><span
                    class="EOP BCX0 SCXW222454910"
                    data-ccp-props='{"201341983":0,"335551550":1,"335551620":1,"335559685":14,"335559737":10159,"335559739":1,"335559740":252,"335559991":370}'
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      font-size: 11pt;
                      line-height: 17.85px;
                      font-family: 'Times New Roman', 'Times New Roman_EmbeddedFont',
                        'Times New Roman_MSFontService', serif;
                      color: rgb(0, 0, 0);
                    "
                    >&nbsp;</span
                  >
                </p>
              </div>
            </div>

            <div style="text-align: center">
              <vs-button style="width: 60%; margin-top: 2rem" @click="$router.go(-1)">{{
                $t("back")
              }}</vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    },
  },
  components: {},
  computed: {},
  created() {},
};
</script>

<style lang="scss">
.succesuss {
  width: 100%;
  margin: auto;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}
.P-Text {
  color: #004477;
}
</style>
